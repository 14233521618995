import { SupermarketPage } from "api"
import {
  AssetListType,
  AssetListWithoutSupermarketType,
  getAssetTypeByString,
} from "common/AssetType"

export const setInfoWindowContentAssets = (
  marker: AssetListType,
  moreInfo: string,
  locale: string
) => {
  const type = getAssetTypeByString(marker?.typeId?.toLowerCase() || "")
  const image =
    type === "supermarket"
      ? (marker as SupermarketPage)?.logo?.media?.publicUrl
      : (marker as AssetListWithoutSupermarketType)?.mainExtended?.logoMobile
          ?.media?.publicUrl
  return `
           ${
             image
               ? `<img style="width:130px;height:90px;border-radius:9px;object-fit:cover;margin-inline-end: 10px;" src="${image}" alt="${marker.title}" />`
               : ""
           }
            <h3 style="font-weight:normal;font-size:18px;margin-bottom:12px">${
              marker.title
            }</h3>

            <span style="color:#d9001d;font-size:14px;"><a href="/${locale}/${
    marker.slug
  }"> ${moreInfo} ></a></div>

        `
}
