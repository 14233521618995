import React from "react"
import styled from "styled-components"

const StyledDiv = styled.div`
  height: 100%;
`
interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  }
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <StyledDiv
      role="tabpanel"
      hidden={value !== index}
      id={`auth-tabpanel-${index}`}
      aria-labelledby={`auth-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </StyledDiv>
  )
}

export default TabPanel
