import { getHome } from "api"
import { UseSiteId } from "api/SiteId"
import { Desktop, Mobile } from "common/MediaQueries"
import { useAssetSearchContext } from "context/AssetSearchContext"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { useEffect } from "react"
import AssetsGoogleMaps from "./AssetsGoogleMaps"
import AssetsResultsList from "./AssetsResultsList"

const DesktopPopUp = dynamic(() => import("./DesktopPopUp"))
const MobilePopUp = dynamic(() => import("./MobilePopUp"))

const SearchPopup = () => {
  const siteId = UseSiteId()
  const {
    showAllResults,
    selectedAssetsResults,
    setHomePageDataCategories,
    homePageDataCategories,
    activeCategoryType,
    popUpIsOpen,
    closeAssetSearch,
    openMapOrList,
  } = useAssetSearchContext()
  const { events } = useRouter()

  useEffect(() => {
    const getPopupAssetsData = async () => {
      const data = await getHome(siteId)
      setHomePageDataCategories(data.data?.categories)
    }
    if (
      (!homePageDataCategories || !homePageDataCategories?.length) &&
      popUpIsOpen
    ) {
      getPopupAssetsData()
    }
  }, [setHomePageDataCategories, siteId, homePageDataCategories, popUpIsOpen])

  useEffect(() => {
    if (popUpIsOpen) {
      showAllResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popUpIsOpen, activeCategoryType])

  useEffect(() => {
    if (!selectedAssetsResults && popUpIsOpen) {
      showAllResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetsResults, popUpIsOpen])

  useEffect(() => {
    events.on("routeChangeStart", closeAssetSearch)

    return () => {
      events.off("routeChangeStart", closeAssetSearch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events])

  return (
    <>
      <Desktop>
        <DesktopPopUp />
      </Desktop>

      <Mobile>
        <MobilePopUp />
      </Mobile>

      {openMapOrList === "map" && <AssetsGoogleMaps />}
      {openMapOrList === "list" && <AssetsResultsList />}
    </>
  )
}

export default SearchPopup
