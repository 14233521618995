import { Box, Button, List, ListItem, Typography } from "@material-ui/core"
import { MallPage } from "api"
import { Mobile } from "common/MediaQueries"
import NextLink from "common/NextLink"
import { LOCALE } from "common/utils/constants"
import { StyledFlexBoxRow } from "components/searchAmot/SearchAmotPage"
import { useTranslations } from "next-intl"
import Image from "next/image"
import { useRouter } from "next/router"
import { Dispatch, SetStateAction } from "react"
import styled from "styled-components"

const StyledWrapper = styled.div`
  margin-bottom: auto;
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    flex-direction: column;
    min-height: 225px;
    height: 100%;
    justify-content: space-between;
    min-width: 200px;
    margin-bottom: 0px;
  }
`

const StyledBack = styled(Typography).attrs({ variant: "body1" })`
  font-size: 16px;
  color: ${(p) => p.theme.colors.gray};
`

const StyledTitle = styled(Typography).attrs({ variant: "body1" })`
  font-size: 24px;
  margin-bottom: 25px;
  margin-top: 30px;
  color: ${(p) => p.theme.colors.black};
  font-weight: 500;
`

const StyledText = styled(Typography).attrs({
  variant: "body1",
})<{
  $locale: string
}>`
  font-size: 16px;
  ${(p) => p.$locale === "en" && "max-width: 146px"};
  color: ${(p) => p.theme.colors.black};
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 14px;
  }
`

const StyledLinkList = styled(List)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    gap: 30px;
  }
`
const StyledCloseContainer = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    justify-content: center;
  }
`
const CloseButton = styled(Button)`
  font-family: FbJabutinskiEng, FbJabutinski;
  font-size: 16px;
  font-weight: 500;

  line-height: 1.38;
  color: #000615;
  display: flex;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
  }
`
const StyledArrow = styled(Image)<{ $hebrew: boolean }>`
  ${(p) =>
    p.$hebrew ? `-webkit-transform: scaleX(-1); transform: scaleX(-1);` : ``}
`
const StyledButtonNoPadding = styled(Button)`
  padding: 0px;
`
type SubLinksListProps = {
  linksObject: any
  title: string
  onClose: () => void

  setMainMenu?: Dispatch<SetStateAction<boolean>>
  layoutItems?: MallPage[] | []
}

const SubLinksList = ({
  linksObject,
  title,
  setMainMenu,
  onClose,
  layoutItems,
}: SubLinksListProps) => {
  const t = useTranslations("headerMenu")
  const { locale } = useRouter()
  return (
    <StyledWrapper>
      <Mobile>
        <StyledButtonNoPadding onClick={() => setMainMenu(true)}>
          <StyledFlexBoxRow $gap="12px">
            <StyledArrow
              src="/arrowLeftIconGray.svg"
              alt="arrow icon"
              width={14}
              height={14}
              $hebrew={locale === "he"}
            />
            <StyledBack>{t("back")}</StyledBack>
          </StyledFlexBoxRow>
        </StyledButtonNoPadding>
        <StyledTitle>{linksObject ? title : t("mallSites")}</StyledTitle>
      </Mobile>
      <div>
        {linksObject && (
          <StyledLinkList aria-label={title}>
            {Object.keys(linksObject).map((key, index) => {
              if (locale === LOCALE.EN && key === "magazine") return null
              return (
                <ListItem key={`${key}-${index}`}>
                  <Box>
                    <NextLink href={linksObject[key]}>
                      <StyledText $locale={locale}>{t(key as any)}</StyledText>
                    </NextLink>
                  </Box>
                </ListItem>
              )
            })}
          </StyledLinkList>
        )}

        <StyledLinkList aria-label={title}>
          {layoutItems &&
            layoutItems.length > 0 &&
            layoutItems
              ?.filter(
                (item) =>
                  (item as MallPage)?.additionalMallInfo?.showInMenu?.value ===
                  true
              )
              .map((key, index) => {
                return (
                  <ListItem key={`${key}-${index}`}>
                    <Box>
                      <NextLink
                        openInNewTab
                        href={key?.additionalMallInfo?.externalSiteLink?.value}
                      >
                        <StyledText $locale={locale}>{key.title}</StyledText>
                      </NextLink>
                    </Box>
                  </ListItem>
                )
              })}
        </StyledLinkList>
        <Mobile>
          <StyledCloseContainer>
            <CloseButton
              onClick={onClose}
              startIcon={
                <Image src="/menu-icon-2.svg" width="12.7px" height="12.7px" />
              }
            >
              {t("close")}
            </CloseButton>
          </StyledCloseContainer>
        </Mobile>
      </div>
    </StyledWrapper>
  )
}

export default SubLinksList
