import { Button } from "@material-ui/core"
import { getAssetTypeByString, getCategoryImageSource } from "common/AssetType"
import CdnImage from "common/utils/CdnImage"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useRouter } from "next/router"
import styled from "styled-components"

const StyledCategories = styled.div`
  display: flex;
  justify-content: center !important;
  flex-wrap: wrap;
  /* padding-bottom: 120px; */
  ${(p) => p.theme.breakpoints.up("md")} {
    padding-bottom: 40px;
    background-color: ${(p) => p.theme.colors.white};
    padding-top: 40px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: solid 1px ${(p) => p.theme.colors.activitiesBorder};
  }
`

const StyledCategory = styled(Button)`
  ${(p) => p.theme.breakpoints.down("sm")} {
    :hover {
      background-color: transparent !important;
    }
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    :not(:first-child) {
      margin-inline-start: 50px;
    }
    width: 70px;
  }
  width: 131px;
  height: 73px;
  margin-bottom: 25px;
  padding: 0;
  .MuiButton-label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const StyledCategoryIcon = styled(CdnImage)`
  margin-bottom: 12px;
`

const StyledCategoryTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  line-height: 1.2;
`

const CategoriesDisplay = () => {
  const {
    setActiveCategoryType,
    activeCategoryType,
    homePageDataCategories,
    setOpenCategoriesDrawer,
    value,
    fetchAssets,
    setSelectedAssetsResult,
  } = useAssetSearchContext()
  const { push } = useRouter()
  const toggleCategories = async (type) => {
    if (type === "supermarket") {
      push("/assets-list/supermarkets")
    } else {
      setActiveCategoryType(type)

      setSelectedAssetsResult(await fetchAssets(value, "", type))
    }
    setOpenCategoriesDrawer(false)
  }

  return (
    <StyledCategories>
      {homePageDataCategories?.map((category, i) => {
        const type = getAssetTypeByString(
          category.link?.page?.typeId?.toLowerCase() || ""
        )
        return (
          <StyledCategory onClick={() => toggleCategories(type)} key={i}>
            <StyledCategoryIcon
              width={54}
              height={54}
              alt=""
              src={getCategoryImageSource(type, activeCategoryType)}
            />
            <StyledCategoryTitle>{category?.title?.value}</StyledCategoryTitle>
          </StyledCategory>
        )
      })}
    </StyledCategories>
  )
}

export default CategoriesDisplay
