import { Container, Dialog, DialogProps } from "@material-ui/core"
import * as api from "api"
import { GlobalSiteSearchModel } from "api"
import { UseSiteId } from "api/SiteId"
import slugify from "common/utils/slugify"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { useDebounce } from "../../common/hooks/useDebounce"
import NoResultsWrap from "./NoResultsWrap"
import SearchBar from "./SearchBar"
import SearchResultBox from "./SearchResultBox"

const StyledDialog = styled(Dialog)`
  z-index: ${(p) => p.theme.zIndex.drawer - 150} !important;
  .MuiDialog-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .MuiDialog-paper {
    border-radius: 16px;
    width: 100vw !important;
    border-radius: 8px;
    margin-top: 75px;
    margin-bottom: 0px;
    max-width: 1280px;

    ${(p) => p.theme.breakpoints.up("md")} {
      right: 7.5px;
      left: -7.5px;
    }
    /* ${(p) => p.theme.breakpoints.down("sm")} {
      margin-left: 20px;
      margin-right: 20px;
    } */
  }
`

const StyledShadowWrap = styled.div`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
  background-color: ${(p) => p.theme.colors.white};
  overflow: hidden;
`
const StyledDivider = styled.div`
  height: 1px;
  background-color: ${(p) => p.theme.colors.activitiesBorder};
  width: 100%;
`
const StyledResultWrapper = styled.div`
  padding: 16px;
  height: 100%;
  max-height: 413px;
  overflow-y: auto;
`

export type FloatingSearchPopUpProps = DialogProps & {
  open: boolean
  setOpen: (value: boolean) => void
  onClose?: () => void
}

const FloatingSearchPopUp = ({
  open,
  setOpen,
  onClose,
  ...props
}: FloatingSearchPopUpProps) => {
  const [searchValue, setSearchValue] = useState("")
  const { push, query } = useRouter()
  const [isSearching, setIsSearching] = useState(false)
  const [searchResult, setSearchResult] = useState<
    GlobalSiteSearchModel[] | []
  >([])
  const debouncedSearchTerm: string = useDebounce<string>(searchValue, 200)
  const siteId = UseSiteId()
  const handleKeyDown = (event) => {
    //9 for NEXT button
    //13 for GO button
    if (event.key === "Enter" || event.keyCode === 13) {
      push({
        pathname: "/search",
        query: {
          searchText: searchValue,
        },
      })
    }
  }

  const handleClose = () => {
    if (setOpen) {
      setOpen(false)
    }
    if (onClose) {
      onClose()
    }
  }

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) {
      let timeout = false
      setIsSearching(true)
      api
        .getSitesSearchSearchParam(debouncedSearchTerm, siteId)
        .then((result) => {
          if (timeout) return
          if (!result || result?.status !== 200) {
            setSearchResult([])
          } else {
            setSearchResult(result?.data)
          }
          setIsSearching(false)
        })

      return () => {
        timeout = true
      }
    }
  }, [debouncedSearchTerm, siteId])

  useEffect(() => {
    setOpen(false)
  }, [query, setOpen])

  useEffect(() => {
    if (open) {
      setSearchValue("")
      setSearchResult([])
    }
  }, [open])

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      scroll="body"
      {...props}
      PaperComponent={Container}
    >
      <StyledShadowWrap>
        <SearchBar
          handleKeyDown={handleKeyDown}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          onSearchIconClick={() => {
            push({
              pathname: "/search",
              query: {
                searchText: slugify(searchValue),
              },
            })
          }}
        />
        <StyledDivider />
        {searchResult?.length === 0 &&
        debouncedSearchTerm.length >= 2 &&
        !isSearching ? (
          <NoResultsWrap searchValue={searchValue} />
        ) : (
          <>
            {!isSearching && debouncedSearchTerm.length >= 2 && (
              <StyledResultWrapper>
                {searchResult.map((item, index) => (
                  <SearchResultBox key={index} data={item} />
                ))}
              </StyledResultWrapper>
            )}
          </>
        )}
      </StyledShadowWrap>
    </StyledDialog>
  )
}

export default FloatingSearchPopUp
