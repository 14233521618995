import { Typography } from "@material-ui/core"
import NextLink from "common/NextLink"
import { useTranslations } from "next-intl"
import styled from "styled-components"

const StyledNoResultsDiv = styled.div`
  width: 100%;
  /* height: 100px; */
  margin-top: 37px;
  margin-bottom: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0 15px;
  }
`
export const StyledNoResultsTitle = styled(Typography).attrs({
  variant: "body1",
})`
  color: ${(p) => p.theme.colors.black};
  font-size: 24px;
  font-weight: 500;
`
export const StyledNoResultsSubTitle = styled(Typography).attrs({
  variant: "body1",
})`
  color: ${(p) => p.theme.colors.black};
  font-size: 16px;
  margin-top: 15px;
  ${(p) => p.theme.breakpoints.down("sm")} {
    width: 100%;
  }
`

const StyledLink = styled.a`
  text-decoration: underline;
`

type Props = {
  searchValue?: string
  isChanging?: boolean
}

const NoResultsWrap = ({ searchValue, isChanging }: Props) => {
  const t = useTranslations("searchWebsite")
  return (
    <StyledNoResultsDiv>
      {!isChanging ? (
        <>
          <StyledNoResultsTitle>
            {t("noResultsTitle")}
            <NextLink passHref href="/">
              <StyledLink>{t("goToHomepage")}</StyledLink>
            </NextLink>
          </StyledNoResultsTitle>
          {searchValue && (
            <StyledNoResultsSubTitle>
              {t("noResultsDescription")} {`"${searchValue}"`}
            </StyledNoResultsSubTitle>
          )}
        </>
      ) : (
        <StyledNoResultsTitle>{t("searching")}</StyledNoResultsTitle>
      )}
    </StyledNoResultsDiv>
  )
}

export default NoResultsWrap
