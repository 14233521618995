import { setInfoWindowContentAssets } from "components/popups/setInfoWindowContentAssets"
import { NewAssetListType, setInfoWindowContent } from "./setInfoWindowContent"

let prevMarker = null

export type customMarker = google.maps.Marker & { tagId: number }

export const createStationMarkers = (
  maps: any,
  markers: NewAssetListType[],
  map: google.maps.Map,
  isAssets: string,
  locale: string,
  refs?: any,
  isAccessible?: string,
  slug?: string
): customMarker[] => {
  const redMarker = {
    url: "/location-icon.svg",
    size: new maps.Size(53.3, 66.3),
    origin: new maps.Point(0, 0),
    anchor: new maps.Point(17, 25),
    scaledSize: new maps.Size(30, 30),
  }

  // this is responsible for infoWindow;
  const infoWindow = new maps.InfoWindow()
  const bounds = new maps.LatLngBounds()
  const markersArray: customMarker[] = []

  markers?.map((marker, index) => {
    const position = {
      lat: parseFloat(marker?.location?.lat?.value?.trim() || "0"),
      lng: parseFloat(marker?.location?.lon?.value?.trim() || "0"),
    }
    if (position.lat == 0 || position.lng == 0) {
      return
    }
    const markerObject: customMarker = new maps.Marker({
      position,
      icon: redMarker,
      type: "station",
      optimized: false,
      title: marker?.title,
      zIndex: 1,
      map: map,
      tagId: marker?.id,
      open: true,
    })
    markersArray.push(markerObject)
    bounds.extend(position)
    markerObject.addListener("click", () => {
      infoWindow.close()
      if (isAssets) {
        infoWindow.setContent(
          setInfoWindowContentAssets(marker, isAssets, locale)
        )
      } else {
        infoWindow.setContent(
          setInfoWindowContent(marker, isAccessible, locale)
        )
      }

      prevMarker = markerObject
      infoWindow.open(markerObject.getMap(), markerObject)
    })

    markerObject.setIcon(redMarker)
    infoWindow.addListener("closeclick", () => {
      if (prevMarker) {
        prevMarker.setIcon(redMarker)
      }
    })

    if (slug === marker.slug) {
      google.maps.event.trigger(markerObject, "click")
    }
    if (refs && refs.current) {
      refs.current[index] = markerObject
    }
  })

  map.fitBounds(bounds)
  const zoom = map.getZoom()
  map.setZoom(zoom > 15 ? 15 : zoom)

  return markersArray
}
