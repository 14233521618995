import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { useTranslations } from "next-intl"
import Image from "next/image"
import React from "react"
import styled from "styled-components"

const StyledShadowWrap = styled.div<{ $withShadow: boolean }>`
  ${(p) =>
    p.$withShadow
      ? `border-radius: 8px; box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16); background-color: #fff;`
      : ``}
`

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: ${(p) => p.theme.colors.white};
    border: none;
    font-size: 16px;
  }
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${(p) => p.theme.colors.white};
  }
  .MuiOutlinedInput-input {
    padding: 20px 0px;
  }
  .MuiOutlinedInput-adornedStart {
    padding-right: 0px;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-left: 3px;
  }
  .MuiInputAdornment-positionStart {
    padding-right: 0px;
    margin-left: 0px;
    margin: 15px;
  }
  .MuiInputAdornment-positionEnd {
    margin: 15px;
  }
`

type SearchBarProps = {
  withShadow?: boolean
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  onSearchIconClick?: () => void
}

const SearchBar = ({
  withShadow = false,
  handleKeyDown,
  setSearchValue,
  searchValue,
  onSearchIconClick,
}: SearchBarProps) => {
  const t = useTranslations("searchWebsite")

  return (
    <StyledShadowWrap $withShadow={withShadow}>
      <StyledTextField
        autoFocus={true}
        variant="outlined"
        placeholder={t("searchPlaceholder")}
        value={searchValue}
        role="search"
        aria-label="סגור רכיב חיפוש עם מקש ESC"
        inputProps={{
          "aria-label": t("searchField"),
          style: { order: 1 },
        }}
        InputProps={{
          onChange: (e) => {
            setSearchValue(e.target.value)
          },
          onKeyDown: handleKeyDown,
          endAdornment: (
            <>
              <InputAdornment style={{ order: 0 }} position={"start"}>
                <IconButton
                  aria-label={t("searchButton")}
                  onClick={onSearchIconClick}
                >
                  <Image
                    src="/searchIconGrey.svg"
                    alt="search-icon"
                    width={"16px"}
                    height={"16px"}
                  />
                </IconButton>
              </InputAdornment>
              {searchValue !== "" && (
                <InputAdornment style={{ order: 1 }} position={"end"}>
                  <IconButton onClick={() => setSearchValue("")}>
                    <Image
                      src="/xIconGreyCircle.svg"
                      alt="x-icon"
                      width={"16px"}
                      height={"16px"}
                    />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </StyledShadowWrap>
  )
}

export default SearchBar
