import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { Desktop } from "common/MediaQueries"
import NextImage from "common/NextImage"
import NextLink from "common/NextLink"
import SearchPopup from "components/popups/SearchPopup"
import FloatingSearchPopUp from "components/searchAmot/FloatingSearchPopUp"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useTranslations } from "next-intl"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import styled from "styled-components"
import HeaderMenu from "../components/headerMenu/HeaderMenu"

const StyledAppBar = styled(AppBar)`
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid #d9d9d9;
  height: 60px;
  justify-content: center;

  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 16px 0;
    z-index: 1302;
  }
`

const StyledToolbar = styled(Toolbar).attrs({ disableGutters: true })`
  justify-content: center;
  min-height: auto;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    padding: 0 5px;
  }
`

const StyledContainer = styled(Container)`
  /* padding: 0; */
`
const StyledButton = styled(Button)<{ $active?: boolean }>`
  padding: 0;

  ${(props) =>
    props.$active &&
    `
  color: ${props.theme.colors.red};
  `};
`

const StyledLanguageButton = styled(Button)<{
  $active: boolean
  component: string
}>`
  padding: 0;

  color: ${(p) => (p.$active ? p.theme.colors.black : p.theme.colors.gray)};
  font-weight: ${(props) => (props.$active ? "500" : "400")};
`
const StyledHeaderSide = styled.div<{ $justify: "start" | "end" }>`
  display: flex;
  align-items: flex-end;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: ${(p) => `flex-${p.$justify}`};
  text-transform: uppercase;

  svg {
    transition: 0.5s all ease;
  }
  svg:hover {
    fill: ${(p) => p.theme.colors.red};
  }
`

const StyledIcon = styled(Image)``

const StyledNextImageContainer = styled.div`
  width: 89px;
  height: 26px;
  position: relative;
  img {
    width: 100% !important;
  }
`

const StyledImageIcon = styled.img`
  align-self: end;
`

const StyledMenuButton = styled(IconButton)`
  width: 40px;
  height: 40px;
`

const StyledBox = styled(Box)<{ $isRtl: boolean }>`
  ${({ theme }) => theme.breakpoints.up("md")} {
    position: relative;
    right: ${(p) => (p.$isRtl ? "auto" : "11px")};
    left: ${(p) => (p.$isRtl ? "11px" : "auto")};
  }
`

const Header = () => {
  const t = useTranslations("header")
  const { locale, route, asPath, events } = useRouter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [menuHover, setMenuHover] = useState(false)
  const { setPopUpIsOpen, setOpenMapOrList, popUpIsOpen, openMapOrList } =
    useAssetSearchContext()
  useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuOpen(false)
    }
    events.on("routeChangeStart", handleRouteChange)

    return () => {
      events.off("routeChangeStart", handleRouteChange)
    }
  }, [events])

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    setIsSearchOpen(false)
  }

  const toggleOpenPopup = () => {
    setPopUpIsOpen((prevState) => !prevState)
    setOpenMapOrList("")
    setIsSearchOpen(false)
    setIsMenuOpen(false)
  }
  // hard reload on locale change to fix mui issues
  const handleLocaleChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    const href = e.currentTarget.getAttribute("href")
    location.href = href
  }

  return (
    <>
      <StyledAppBar position="fixed">
        <StyledContainer>
          <StyledToolbar>
            {/*Right side*/}
            <StyledHeaderSide $justify={"start"}>
              <StyledBox
                $isRtl={locale === "he"}
                display="flex"
                alignItems="center"
              >
                <Box>
                  <StyledMenuButton
                    aria-expanded={isMenuOpen ? true : false}
                    onClick={handleOpenMenu}
                    onMouseEnter={() => setMenuHover(true)}
                    onMouseLeave={() => setMenuHover(false)}
                    aria-label={isMenuOpen ? "סגירת תפריט" : "פתיחת תפריט"}
                  >
                    {isMenuOpen ? (
                      <img src="/close.svg" alt="" />
                    ) : menuHover ? (
                      <img src="/hover.svg" alt="" />
                    ) : (
                      <img src="/inactive black.svg" alt="" />
                    )}
                  </StyledMenuButton>
                </Box>
                <Desktop>
                  <Box ml={7} style={{ lineHeight: 2 }}>
                    <StyledButton
                      aria-expanded={
                        popUpIsOpen || openMapOrList ? true : false
                      }
                      $active={!!(popUpIsOpen || openMapOrList)}
                      onClick={toggleOpenPopup}
                    >
                      <Typography color="inherit">
                        {t("assetsSearch")}
                      </Typography>
                    </StyledButton>
                  </Box>
                  <Box ml={7} style={{ lineHeight: 2 }}>
                    <NextLink href="/contact-us" passHref>
                      <StyledButton role="link">
                        <Typography color="inherit">
                          {t("contactUs")}
                        </Typography>
                      </StyledButton>
                    </NextLink>
                  </Box>
                </Desktop>
              </StyledBox>
            </StyledHeaderSide>
            {/*Center*/}
            <div>
              <NextLink href="/">
                <StyledNextImageContainer>
                  <NextImage
                    image={{
                      imageUrl: "/Logonew2x.png",
                      alt: t("title"),
                    }}
                  />
                </StyledNextImageContainer>
              </NextLink>
            </div>
            {/*Left side*/}
            <StyledHeaderSide $justify={"end"}>
              <Box display="flex" alignItems="center">
                <Desktop>
                  <Box style={{ lineHeight: 2 }}>
                    <Link href={route} as={asPath} locale="en" passHref>
                      <StyledLanguageButton
                        component="a"
                        $active={locale === "en"}
                        onClick={handleLocaleChange}
                        aria-label="to the english website"
                      >
                        <Typography color="inherit">English</Typography>
                      </StyledLanguageButton>
                    </Link>
                  </Box>
                  <Box ml={7} style={{ lineHeight: 2 }}>
                    <Link href={route} as={asPath} locale="he" passHref>
                      <StyledLanguageButton
                        component="a"
                        $active={locale === "he"}
                        onClick={handleLocaleChange}
                        aria-label="לאתר בשפה העברית"
                      >
                        <Typography color="inherit">עברית</Typography>
                      </StyledLanguageButton>
                    </Link>
                  </Box>
                  <Box ml={7}>
                    <IconButton
                      aria-label="טלפון, קישור לטלפון"
                      href="tel:88005553535"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <StyledIcon
                        src={"/telephoneicon.svg"}
                        width={17}
                        height={17}
                      />
                    </IconButton>
                  </Box>
                </Desktop>
                <Box ml={7}>
                  <IconButton
                    aria-label="פתח רכיב חיפוש"
                    onClick={() => {
                      setIsSearchOpen(!isSearchOpen)
                      setIsMenuOpen(false)
                      setPopUpIsOpen(false)
                    }}
                  >
                    {isSearchOpen ? (
                      <StyledImageIcon
                        src={"/searchiconRed.svg"}
                        width={17}
                        height={17}
                        loading="lazy"
                      />
                    ) : (
                      <StyledImageIcon
                        src={"/searchicon.svg"}
                        width={17}
                        height={17}
                        loading="lazy"
                      />
                    )}
                  </IconButton>
                </Box>
              </Box>
            </StyledHeaderSide>
          </StyledToolbar>
        </StyledContainer>
      </StyledAppBar>
      <HeaderMenu isMenuOpen={isMenuOpen} onClose={handleOpenMenu} />
      <FloatingSearchPopUp open={isSearchOpen} setOpen={setIsSearchOpen} />
      <SearchPopup />
    </>
  )
}

export default Header
