import { Box, List, ListItem } from "@material-ui/core"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"

const StyledLanguageButton = styled.div<{ $active: boolean }>`
  color: ${(p) => (p.$active ? p.theme.colors.black : p.theme.colors.gray)};
  font-weight: ${(p) => (p.$active ? 500 : "normal")};
  font-size: 14px;
`

const StyledMobileList = styled(List)`
  display: flex;
`

const EnglishHebrewButtons = () => {
  const { route, locale,asPath } = useRouter()
  const handleLocaleChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault()
    const href = e.currentTarget.getAttribute("href")
    location.href = href
  }
  return (
    <Box display="flex" justifyContent="center" mb={6.66}>
      <StyledMobileList aria-label="switching languages">
        <ListItem>
          <Box mr={2.5}>
            <StyledLanguageButton $active={locale === "he"}>
              <Link href={route} as={asPath} locale="he" passHref>
                <a onClick={handleLocaleChange}>עברית</a>
              </Link>
            </StyledLanguageButton>
          </Box>
        </ListItem>
        <ListItem>
          <StyledLanguageButton $active={locale === "en"}>
            <Link href={route} as={asPath} locale="en">
              <a onClick={handleLocaleChange}>English</a>
            </Link>
          </StyledLanguageButton>
        </ListItem>
      </StyledMobileList>
    </Box>
  )
}

export default EnglishHebrewButtons
