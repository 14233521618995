import { SupermarketPage } from "api"
import {
  AssetListType,
  AssetListWithoutSupermarketType,
  getAssetTypeByString
} from "common/AssetType"

export type NewAssetListType = AssetListType & {
  newCityTranslate: string
}

export const setInfoWindowContent = (
  marker: NewAssetListType,
  isAccessible: string,
  locale: string
) => {

  const type = getAssetTypeByString(marker?.typeId?.toLowerCase() || "")
  const image =
    type === "supermarket"
      ? (marker as SupermarketPage)?.logo?.media?.publicUrl
      : (marker as AssetListWithoutSupermarketType)?.mainExtended?.logoMobile
          ?.media?.publicUrl
  const address =  `${locale === 'en'? marker?.location?.building?.value || "" : ""} ${marker?.location?.street?.value || ""} ${locale === 'he'? marker?.location?.building?.value || "" : ""} ${marker?.newCityTranslate}`
  return `
            ${
              image
                ? `<img src=${image} alt={${marker.title}} class="logo"/>`
                : ""
            }
            <h3 style="color:#000615; font-family: 'FbJabutinskiEng', 'FbJabutinski'; font-weight: normal"; font-size: 18px;>${
              marker.title
            }</h3>
            <span style="color:#5b626a; font-family:  'FbJabutinskiEng', 'FbJabutinski'; margin-top:5px;">
              ${address}
            </span>
            ${
              marker.main.isAccessable &&
              `<div class="accessible" style="color:#356f9a; font-family:  'FbJabutinskiEng', 'FbJabutinski';"> <img src="/material-accessible.png" alt="accesible logo"/> ${isAccessible}</div>`
            }
        `
}
