import { Box, Container, Typography } from "@material-ui/core"
import * as api from "api"
import { GlobalSiteSearchModel } from "api"
import { UseSiteId } from "api/SiteId"
import { unSlugify } from "common/utils/slugify"
import { useTranslations } from "next-intl"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { useDebounce } from "../../common/hooks/useDebounce"
import NoResultsWrap from "./NoResultsWrap"

import SearchBar from "./SearchBar"
import SearchResultBox from "./SearchResultBox"
//Styles
const StyledContainer = styled(Container)`
  position: relative;
  z-index: 21;
  display: flex;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 50px;
`
const StyledStretch = styled.div`
  width: 100%;
  box-sizing: border-box;
`
const StyledTitle = styled(Typography).attrs({
  variant: "h2",
  component: "h1",
})`
  font-size: 20px;
  line-height: 2;
  letter-spacing: 0.4px;
  padding: 10px 0px;
  height: 60px;
`

export const StyledFlexBoxRow = styled(Box)<{
  $center?: boolean
  $mb?: string
  $mt?: string
  $gap?: string
  $width?: string
  $height?: string
  $fullWidth?: boolean
  $justify?: string
  $align?: string
}>`
  margin: 0px;
  margin-bottom: ${(p) => (p.$mb ? p.$mb : "0")};
  margin-top: ${(p) => (p.$mt ? p.$mt : "0")};
  display: flex;
  flex-direction: row;
  gap: ${(p) => p.$gap};
  box-sizing: border-box;
  place-items: ${(p) => (p.$center ? "center" : "none")};
  width: 100%;
  width: ${(p) => p.$width};
  height: ${(p) => p.$height};
  justify-content: ${(p) => (p.$justify ? p.$justify : "initial")};
  align-items: ${(p) => (p.$align ? p.$align : "initial")};
`

export const StyledFlexBoxCol = styled(StyledFlexBoxRow)<{
  $center?: boolean
  $mb?: string
  $mt?: string
  $gap?: string
  $width?: string
  $height?: string
  $fullWidth?: boolean
  $justify?: string
  $align?: string
}>`
  flex-direction: column;
`
const StyledNoResultsDiv = styled.div`
  width: 100%;
  margin-top: 37px;
  margin-bottom: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`
const SearchAmotPage = () => {
  const router = useRouter()
  const { query } = router
  const t = useTranslations("searchWebsite")
  const [firstPageRender, setFirstPageRender] = useState(false)
  const [numberOfResults, setNumberOfResults] = useState(0)
  const [searchValue, setSearchValue] = useState("")
  const [isSearching, setIsSearching] = useState(false)
  const [searchResult, setSearchResult] = useState<
    GlobalSiteSearchModel[] | []
  >([])
  const debouncedSearchTerm: string = useDebounce<string>(searchValue, 200)
  const siteId = UseSiteId()

  // Effect for API call
  useEffect(() => {
    let timeout = false

    setIsSearching(true)
    api
      .getSitesSearchSearchParam(debouncedSearchTerm, siteId)
      .then((result) => {
        if (timeout) return
        if (!result || result?.status !== 200) {
          setNumberOfResults(0)
          setSearchResult([])
        } else {
          setNumberOfResults(result?.data?.length)
          setSearchResult(result?.data)
        }
        setIsSearching(false)
        router.replace({
          pathname: "/search",
          query: {
            searchText: debouncedSearchTerm,
          },
        })
      })

    return () => {
      timeout = true
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, siteId])

  useEffect(() => {
    // on first render check query and set result to be related to
    if (query.searchText && !firstPageRender) {
      setSearchValue(unSlugify(query.searchText as string))
      setFirstPageRender(true)
    }
  }, [query, firstPageRender])

  //Render
  return (
    <>
      <StyledContainer>
        <StyledFlexBoxCol>
          <SearchBar
            withShadow={true}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          />
          {searchResult?.length !== 0 ? (
            <StyledTitle>{`${numberOfResults} ${t(
              "searchResultNumber"
            )} "${debouncedSearchTerm}"`}</StyledTitle>
          ) : (
            <>
              {!isSearching && (
                <StyledNoResultsDiv>
                  <NoResultsWrap searchValue={searchValue} />
                </StyledNoResultsDiv>
              )}
            </>
          )}

          <StyledStretch>
            {searchResult.map((item, index) => (
              <SearchResultBox key={index} data={item} />
            ))}
          </StyledStretch>
        </StyledFlexBoxCol>
      </StyledContainer>
    </>
  )
}

export default SearchAmotPage
