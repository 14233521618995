import { Typography } from "@material-ui/core"
import { GlobalSiteSearchModel } from "api"
import { useIsDesktop } from "common/MediaQueries"
import NextLink from "common/NextLink"
import CdnImage from "common/utils/CdnImage"
import { useTranslations } from "next-intl"
import Image from "next/image"
import { useRouter } from "next/router"
import styled from "styled-components"
import { fontCondensed } from "theme/GlobalStyle"
import { StyledFlexBoxCol, StyledFlexBoxRow } from "./SearchAmotPage"

const StyledWrapper = styled.div`
  display: flex;
  max-width: 100%;
  padding: 15px 0px;

  background-color: ${(p) => p.theme.colors.white};
  box-sizing: border-box;
  :not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.colors.activitiesBorder};
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    display: block;
  }
`

const StyledTitle = styled(Typography).attrs({
  variant: "body1",
  component: "h3",
})`
  ${fontCondensed};
  color: ${(p) => p.theme.colors.black};
  font-size: 18px;
  line-height: 1.22;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`
const StyledSubtitle = styled(Typography).attrs({
  variant: "body1",
  component: "div",
})<{ locale: string }>`
  color: ${(p) => p.theme.colors.darkGray};
  font-size: 14px;
  line-height: 1.22;
  flex-shrink: 0;
  position: relative;
  max-height: 1.2em;

  padding: ${(p) => (p.locale === "he" ? "0 0 0 0" : "0 0.5rem 0 0")};
  p {
    padding: 0;
    margin: 0;
  }
  overflow: hidden;
  /* ::before {
    position: absolute;
    content: "...";
    inset-block-end: 0;
    inset-inline-end: 0;
  } */
`
const StyledLink = styled(Typography).attrs({ variant: "body1" })`
  ${fontCondensed};
  color: ${(p) => p.theme.colors.red};
  font-size: 14px;
  line-height: 1.57;
`
const StyledImageContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.alabaster};
  border-radius: 8px;
  flex-shrink: 0;
  width: 100px;
  height: 75px;
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 127px;
    height: 90px;
  }
`
const StyledImage = styled(CdnImage)`
  border-radius: 4px;
`

const StyledTextWrapper = styled.div`
  max-width: calc(100% - 124px);
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  ${(p) => p.theme.breakpoints.up("md")} {
    max-width: calc(100% - 125px);
  }
`
const StyledArrow = styled(Image)<{ $hebrew: boolean }>`
  ${(p) =>
    p.$hebrew ? `` : `-webkit-transform: scaleX(-1); transform: scaleX(-1);`}
`

type SearchResultBoxProps = {
  data: GlobalSiteSearchModel & { description?: string }
}

const SearchResultBox = ({ data }: SearchResultBoxProps) => {
  const isDesktop = useIsDesktop()
  const t = useTranslations("searchWebsite")
  const { locale } = useRouter()

  const SUPERMARKET_PAGE =
    "Shared.Pages.Assets.Supermarkets.SupermarketPage, Shared, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
  const INVESTOR_PAGE =
    "Shared.Pages.Investors.InvestorsPage, Shared, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"

  const getHref = () => {
    if (data.type === SUPERMARKET_PAGE) {
      return `/assets-list/supermarkets?slug=${data?.slug}`
    }

    if (data.type === INVESTOR_PAGE) {
      return data?.reportFileLink ? `${data.reportFileLink}` : `/${data?.slug}`
    }

    return `/${data?.slug}`
  }

  return (
    <StyledWrapper>
      <NextLink href={`${getHref()}`}>
        <StyledFlexBoxRow $gap="24px">
          <StyledImageContainer>
            <>
              {data.type === INVESTOR_PAGE ? (
                <StyledImage
                  src={"/documentIcon.svg"}
                  alt={data?.title}
                  width="34px"
                  height="44px"
                  objectFit="contain"
                />
              ) : (
                data?.image && (
                  <StyledImage
                    src={data?.image}
                    alt={data?.title}
                    width={isDesktop ? "127px" : "100px"}
                    height={isDesktop ? "90px" : "75px"}
                    objectFit="cover"
                  />
                )
              )}
            </>
          </StyledImageContainer>
          <StyledTextWrapper>
            <StyledFlexBoxCol $gap={isDesktop ? "7px" : "5px"}>
              <StyledTitle>{data?.title}</StyledTitle>
              <StyledSubtitle
                locale={locale}
                dangerouslySetInnerHTML={{
                  __html: data?.description?.slice(0, isDesktop ? 220 : 100),
                }}
              />
            </StyledFlexBoxCol>

            <StyledFlexBoxRow $gap="8px" $align="center">
              <StyledLink>{t("learnMore")}</StyledLink>

              <StyledArrow
                src="/arrowIcon.svg"
                alt="search-icon"
                width={"4.9px"}
                height={"9.2px"}
                $hebrew={locale === "he"}
              />
            </StyledFlexBoxRow>
          </StyledTextWrapper>
        </StyledFlexBoxRow>
      </NextLink>
    </StyledWrapper>
  )
}

export default SearchResultBox
