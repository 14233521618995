import {
  Button,
  ClickAwayListener,
  Container,
  Dialog,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core"
import { Desktop, Mobile } from "common/MediaQueries"
import useLocationLocale from "common/hooks/useLocationLocale"
import { mapsApiKey } from "common/utils/constants"
import NoResultsWrap from "components/searchAmot/NoResultsWrap"
import { customMarker } from "components/supermarkets/SuperMarketMap"
import { createStationMarkers } from "components/supermarkets/createStationMarkers"
import { useAssetSearchContext } from "context/AssetSearchContext"
import GoogleMapReact from "google-map-react"
import { useTranslations } from "next-intl"
import Image from "next/image"
import { useRouter } from "next/router"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import colors from "theme/colors"
import CategoriesDisplay from "./CategoriesDisplay"
import CategoryAndListOrMap from "./CategoryAndListOrMap"
import { FormAssetsSearch } from "./FormAssetsSearch"

export const StyledArrow = styled(IconButton)`
  right: 0;
  top: 10px;
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`
const StyledDialog = styled(Dialog).attrs({ fullScreen: true })`
  .MuiBackdrop-root {
    top: inherit;
  }
  .MuiDialog-container {
    &:focus-visible {
      border: 1px dashed white;
      outline-offset: 2px;
      outline: 2px solid black;
    }
  }
  .MuiDialog-paper.MuiPaper-root {
    top: inherit;
    box-shadow: none;
    background-color: transparent;
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    right: 0;
    top: 60px !important;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    padding-top: 75px;
  }
  .gm-style .gm-style-iw-c {
    padding: 20px !important;
  }
  .gm-ui-hover-effect {
    top: 0 !important;
    left: 0 !important;
  }

  .gmnoprint[role="menubar"] {
    background-color: ${colors.white};
    border-radius: 25px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 3px !important;
    z-index: 10 !important;
    margin-bottom: 20px !important;
    button {
      border-radius: 20px !important;
      border: none !important;
      background-color: ${colors.white} !important;
      &[aria-checked="true"] {
        background-color: ${colors.red} !important;
        color: ${colors.white} !important;
      }
      box-shadow: none !important;

      & + ul {
        display: none;
      }
    }
  }
`

const StyledContainerWrapper = styled(Container)<{
  $openCategoriesDrawer?: boolean
}>`
  height: 605px;
  position: relative;
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: ${(p) =>
      p.$openCategoriesDrawer ? ` calc(100% - 350px)` : `calc(100% - 100px)`};
    padding-right: 10px;
    padding-left: 10px;
  }
`

const StyledInputWrapper = styled.div<{ $openCategoriesDrawer?: boolean }>`
  border-radius: 7px;
  position: absolute;
  background: white;
  z-index: 1203;
  width: 100%;
  top: 15px;
  right: 0;
  left: 0;
  padding-inline-start: 22px;
  ${(p) => p.theme.breakpoints.up("md")} {
    top: ${(p) => (p.$openCategoriesDrawer ? `10px` : `0`)};
    width: 100%;
    right: 0;
    margin: 0;
    padding: 0;
    border-radius: 20px;

    .MuiButtonBase-root {
      ${(p) =>
        !p.$openCategoriesDrawer &&
        `margin-inline-end:0;margin-inline-start:auto;`}

      ${(p) =>
        p.$openCategoriesDrawer &&
        `margin-inline-end:0;
          margin-inline-start:auto;
          &:first-child{
          margin-inline-start:0;

          }
          `}
    }
    .MuiBox-root {
      padding: 20px;
    }
  }
  .MuiTextField-root {
    height: auto;
    min-width: 0;
    width: 100%;
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    height: 57px;
    .MuiBox-root {
      padding-inline-end: 15px;
    }
  }
`

const StyledMapWrapper = styled.div<{ $openCategoriesDrawer?: boolean }>`
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 10px;
    width: 100%;
    height: 100%;
    background-color: white;

    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    > div {
      height: calc(100% - 130px) !important;
      top: 130px;
      > div {
        border-radius: 10px;
      }
    }
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 20px;
    width: 100%;
    height: ${(p) => (p.$openCategoriesDrawer ? `0` : `100%`)};
    background-color: white;
    border-radius: 20px;
    > div {
      height: calc(100% - 47px) !important;
      top: 47px;
      > div {
        border-radius: 10px;
      }
    }
  }
`
const StyledDrawerCustom = styled(Drawer)`
  width: 100%;
  height: 100%;
  inset: 120px 0 0 0;
  display: block;
  position: fixed;
  z-index: 1202;
  overflow: hidden;
  ${(props) => (!props.open ? `pointer-events: none;` : ``)};
  .MuiDrawer-paper {
    border-radius: 0;
    z-index: 4500;
    border: 0;
    position: absolute;
    inset: 0 16px auto;
    width: auto;
    top: -5px;

    background: transparent;
    overflow: hidden;
    padding: 0;

    ${(p) => p.theme.breakpoints.up("md")} {
      padding: 0 100px;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
      inset: 0 10px auto;
    }
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    top: 71px;
  }
`
const StyledCategoryWrap = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
  background: white;
  padding-top: 80px;

  width: 100%;

  ${(p) => p.theme.breakpoints.down("sm")} {
    border-radius: 20px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-top: 25px;
  }
`

const StyledNoResultContainer = styled.div`
  display: flex;
  text-align: center;
  padding: 0 16px;

  padding-top: 60px;
  background-color: white;
  border-radius: 20px;

  ${(p) => p.theme.breakpoints.down("sm")} {
    padding-top: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
`
const StyledCloseContainer = styled.div`
  justify-content: center;
  flex-shrink: 0;
  position: relative;
  inset-block-start: 5px;
  inset-inline-start: 15px;
  z-index: 1;
  ${(p) => p.theme.breakpoints.up("md")} {
    border-radius: 25px;
    width: 50px;
    height: 50px;
    position: absolute;
    inset: 0 42px 0 42px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .MuiButton-startIcon {
      margin: 0;
    }
  }
`
const CloseButton = styled(Button)`
  font-family: FbJabutinskiEng, FbJabutinski;
  font-size: 16px;
  line-height: 1.38;
  color: #000615;
  display: flex;
  font-weight: 500;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
  }
`

const AssetsGoogleMaps = () => {
  const [map, setMap] = useState<google.maps.Map>(null)
  const [maps, setMaps] = useState<any>(null)
  const [showMap, setShowMap] = useState(false)
  const [activeMarkers, setActiveMarkers] = useState<customMarker[]>([])
  const { locale } = useRouter()
  const {
    setSelectedAssetsResult,
    selectedAssetsResults,
    activeCategoryType,
    setOpenCategoriesDrawer,
    openCategoriesDrawer,
    openMapOrList,
    setOpenMapOrList,
  } = useAssetSearchContext()
  const t = useTranslations("searchPopup")
  const mapLocation = useLocationLocale()
  useEffect(() => {
    if (openMapOrList !== "map") {
      setShowMap(false)
    }
  }, [openMapOrList])

  const clearMarkers = useCallback(() => {
    activeMarkers.forEach((marker) => {
      marker.setMap(null)
    })
  }, [activeMarkers])

  useEffect(() => {
    clearMarkers()
    if (!selectedAssetsResults || !map || !maps) return
    const changesMarkers = selectedAssetsResults.map((marker) => ({
      ...marker,
      newCityTranslate: mapLocation(marker.location?.strCity),
    }))
    setActiveMarkers(
      createStationMarkers(maps, changesMarkers, map, t("moreInfo"), locale)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssetsResults, maps, map])

  const handleClose = () => {
    setOpenMapOrList("")
  }

  return (
    <StyledDialog
      open={openMapOrList === "map"}
      onClose={handleClose}
      onAnimationEnd={() => setShowMap(true)}
      PaperProps={{
        "aria-modal": true,
        tabIndex: -1,
      }}
      aria-labelledby="dialogTitle"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <StyledContainerWrapper $openCategoriesDrawer={openCategoriesDrawer}>
          <Desktop>
            <StyledCloseContainer>
              <CloseButton
                onClick={handleClose}
                startIcon={
                  <Image
                    alt=""
                    src="/menu-icon-2.svg"
                    width="12.7px"
                    height="12.7px"
                  />
                }
              ></CloseButton>
            </StyledCloseContainer>
          </Desktop>
          <Typography id="dialogTitle" component="h2" variant="srOnly">
            {t("assetSearchMapTitle")}
          </Typography>
          <Container style={{ position: "relative" }}>
            <StyledInputWrapper $openCategoriesDrawer={openCategoriesDrawer}>
              <FormAssetsSearch
                autoComplete="search-assets"
                onSearch={setSelectedAssetsResult}
                name="search-assets"
                label={`${t("lookingFor")}${t(activeCategoryType)}`}
                extraLabel={t("assetSearchMapTitle")}
              />
            </StyledInputWrapper>
          </Container>

          <Container>
            <StyledDrawerCustom
              variant="persistent"
              open={openCategoriesDrawer}
              anchor="top"
              PaperProps={{ component: Container }}
            >
              <StyledCategoryWrap>
                <Mobile>
                  <StyledArrow onClick={() => setOpenCategoriesDrawer(false)}>
                    <Image width={14} height={7} src="/arrow-simple-down.svg" />
                  </StyledArrow>
                </Mobile>

                <CategoriesDisplay />
              </StyledCategoryWrap>
            </StyledDrawerCustom>
          </Container>
          <Mobile>
            <CategoryAndListOrMap />
          </Mobile>

          {showMap &&
            (!openCategoriesDrawer && selectedAssetsResults?.length === 0 ? (
              <StyledNoResultContainer>
                <NoResultsWrap />
              </StyledNoResultContainer>
            ) : (
              <StyledMapWrapper $openCategoriesDrawer={openCategoriesDrawer}>
                {!openCategoriesDrawer && (
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: mapsApiKey,
                      language: locale === "he" ? "iw" : "en",
                      libraries: ["geometry"],
                    }}
                    // options={mapOptions}
                    defaultCenter={{
                      lat: 31.739798850747462,
                      lng: 34.557425805228085,
                    }}
                    defaultZoom={8}
                    fullscreenControl={false}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => {
                      setMap(map as google.maps.Map)
                      setMaps(maps)
                    }}
                    options={() => ({
                      fullscreenControl: false,
                      mapTypeControl: true,
                      mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.BOTTOM_CENTER,
                      },
                    })}
                  />
                )}
              </StyledMapWrapper>
            ))}
        </StyledContainerWrapper>
      </ClickAwayListener>
    </StyledDialog>
  )
}

export default AssetsGoogleMaps
