// function encodeURISlug(name: string) {
//   return process.env.NODE_ENV !== "development"
//     ? name
//     : encodeURIComponent(name)
// }

export default function slugify(name: string) {
  return name.toLowerCase().trim().replace(/\s+/g, "-")
  // return forceEncode ? encodeURISlug(slug) : slug
}

export function unSlugify(name: string) {
  return name.toLowerCase().trim().replace("-", "  ")
  // return forceEncode ? encodeURISlug(slug) : slug
}
// function that extract only the last part of a slug

export function getSlugLastPart(slug: string) {
  return slug?.split("/").pop() || ""
}
