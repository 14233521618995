import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core"
import { MallPage } from "api"
import { Desktop, Mobile, useIsDesktop } from "common/MediaQueries"
import NextLink from "common/NextLink"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useLayoutContext } from "context/LayoutContext"
import { useTranslations } from "next-intl"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { MainRoutes } from "~/routes/mainRoutes"
import EnglishHebrewButtons from "./EnglishHebrewButtons"
import HeaderDesktopTabs from "./HeaderDesktopTabs"
import SubLinksList from "./SubLinksList"

const StyledDrawer = styled(Drawer)<{ $isEn: boolean }>`
  top: 0;

  ${({ theme }) => theme.breakpoints.up("md")} {
    top: 60px !important;
    z-index: 1301 !important;
  }

  .MuiBackdrop-root {
    top: inherit;
  }
  .MuiPaper-root {
    top: inherit;

    ${(props) =>
      props.$isEn &&
      `
      border-radius: 0;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
    `};

    ${({ theme }) => theme.breakpoints.up("md")} {
      border-radius: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      align-items: center;
    }
  }
`
const StyledWrapper = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 30px 0px 40px;
  display: flex;

  height: 100%;
  min-height: 300px;

  ${(p) => p.theme.breakpoints.down("sm")} {
    padding: 0 16px 23px 16px;
    flex-direction: column;
    min-width: min(90vw, 320px);
  }
  ${({ theme }) => theme.breakpoints.up("md")} {
    right: 15px;
    position: relative;
    left: 0;
    width: calc(100% - 15px);
    justify-content: space-between;
  }
`

const StyledBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const StyledArrow = styled(Image)<{ $hebrew: boolean }>`
  ${(p) =>
    p.$hebrew ? `` : `-webkit-transform: scaleX(-1); transform: scaleX(-1);`}
`
const StyledMainList = styled(List)`
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`
const StyledCloseButton = styled(Image)`
  &:hover {
    fill: red !important;
  }
`
const StyledText = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-size: 14px;
  }
`

const StyledSocialCloseWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 25px;
`

const StyledCloseContainer = styled.div`
  margin-bottom: 15px;
  ${(p) => p.theme.breakpoints.up("md")} {
    justify-content: center;
    flex-shrink: 0;
    margin-top: -8px;
    margin-bottom: 0;
  }
`
const CloseButton = styled(Button)`
  font-family: FbJabutinskiEng, FbJabutinski;
  font-size: 16px;
  font-weight: 500;

  line-height: 1.38;
  color: #000615;
  display: flex;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
  }
`

const StyledContainer = styled(Container)`
  height: 100%;
`
//Types
type HeaderMenuProps = {
  isMenuOpen: boolean
  onClose: () => void
}

const HeaderMenu: React.FC<HeaderMenuProps> = ({ isMenuOpen, onClose }) => {
  const { mallsItems } = useLayoutContext()

  const t = useTranslations("headerMenu")
  const tHeader = useTranslations("header")
  const { locale } = useRouter()
  const isDesktopScreen = useIsDesktop()
  const [selectedValue, setSelectedValue] = useState(0)
  const [mainMenu, setMainMenu] = useState(true)
  const headerOptionsList = [
    "assetsList",
    "investments",
    "relations",
    "amot360",
    "construction",
  ] as const
  const { setPopUpIsOpen } = useAssetSearchContext()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedValue(newValue)
  }

  const toggleOpenSearch = () => {
    onClose()
    setPopUpIsOpen(true)
  }
  useEffect(() => {
    if (!isMenuOpen) {
      setSelectedValue(0)
      setMainMenu(true)
    }
  }, [isMenuOpen])
  //Render
  return (
    // <StyledContainer>
    <StyledDrawer
      anchor={isDesktopScreen ? `top` : locale === "he" ? "left" : "right"}
      open={isMenuOpen}
      onClose={onClose}
      $isEn={locale === "en"}
    >
      <StyledContainer>
        <StyledWrapper>
          <Mobile>
            <Box mb={2} display="flex" justifyContent="space-between">
              <IconButton onClick={onClose} aria-label="close menu">
                <StyledCloseButton
                  src={"/closeMenu.svg"}
                  width="14"
                  height="16"
                />
              </IconButton>
              <NextLink href="/">
                <Image
                  src={"/Logonew2x.png"}
                  alt={tHeader("title")}
                  width="87"
                  height="26"
                  priority
                />
              </NextLink>
              <Box width={14} />
            </Box>
            <EnglishHebrewButtons />
          </Mobile>
          {isDesktopScreen || (!isDesktopScreen && mainMenu) ? (
            <>
              <div>
                <Desktop>
                  <HeaderDesktopTabs
                    onClose={onClose}
                    selectedValue={selectedValue}
                    handleChange={handleChange}
                  />
                </Desktop>
                <Mobile>
                  <StyledMainList aria-label="main-links">
                    {headerOptionsList.map((option, index) => {
                      if (option === "amot360") {
                        return (
                          <NextLink key={option} href="/amot360">
                            <StyledBox mb={5}>
                              <StyledText>{t(option)}</StyledText>
                            </StyledBox>
                          </NextLink>
                        )
                      }
                      if (option === "construction") {
                        return (
                          <NextLink key={option} href="/construction">
                            <StyledBox mb={5}>
                              <StyledText>{t(option)}</StyledText>
                            </StyledBox>
                          </NextLink>
                        )
                      }
                      return (
                        <ListItem
                          key={option}
                          onClick={() => {
                            setSelectedValue(index)
                            setMainMenu(false)
                          }}
                        >
                          <StyledBox mb={5}>
                            <StyledText>{t(option)}</StyledText>
                            <Mobile>
                              {Object.keys(MainRoutes[option]).length > 0 && (
                                <StyledArrow
                                  src="/arrowLeftIcon.svg"
                                  alt="arrow icon"
                                  width={8}
                                  height={14}
                                  $hebrew={locale === "he"}
                                />
                              )}
                            </Mobile>
                          </StyledBox>
                        </ListItem>
                      )
                    })}
                    <ListItem
                      key={"mallsAndShopping"}
                      onClick={() => {
                        setSelectedValue(headerOptionsList.length)
                        setMainMenu(false)
                      }}
                    >
                      <StyledBox mb={5}>
                        <StyledText>{t("mallsAndShopping")}</StyledText>
                        <Mobile>
                          {mallsItems?.filter(
                            (item) =>
                              (item as MallPage)?.additionalMallInfo?.showInMenu
                                ?.value === true
                          ).length > 0 && (
                            <StyledArrow
                              src="/arrowLeftIcon.svg"
                              alt="arrow icon"
                              width={8}
                              height={14}
                              $hebrew={locale === "he"}
                            />
                          )}
                        </Mobile>
                      </StyledBox>
                    </ListItem>
                    <ListItem>
                      <NextLink href="/contact-us">
                        <StyledBox mb={5}>
                          <StyledText>{t("contactUs")}</StyledText>
                        </StyledBox>
                      </NextLink>
                    </ListItem>
                    <ListItem onClick={toggleOpenSearch}>
                      <StyledBox mb={2}>
                        <StyledText>{t("assetsSearch")}</StyledText>
                      </StyledBox>
                    </ListItem>
                  </StyledMainList>
                  <StyledCloseContainer>
                    <CloseButton
                      onClick={onClose}
                      startIcon={
                        <Image
                          src="/menu-icon-2.svg"
                          width="12.7px"
                          height="12.7px"
                        />
                      }
                    >
                      {t("close")}
                    </CloseButton>
                  </StyledCloseContainer>
                </Mobile>
              </div>
            </>
          ) : (
            <>
              <SubLinksList
                onClose={onClose}
                title={t(headerOptionsList[selectedValue])}
                linksObject={MainRoutes[headerOptionsList[selectedValue]]}
                setMainMenu={setMainMenu}
                layoutItems={selectedValue === 5 ? mallsItems : null}
              />
            </>
          )}

          <Desktop>
            <StyledSocialCloseWrapper>
              <StyledCloseContainer>
                <CloseButton
                  onClick={onClose}
                  startIcon={
                    <Image
                      src="/menu-icon-2.svg"
                      width="12.7px"
                      height="12.7px"
                    />
                  }
                >
                  {t("close")}
                  <Typography variant="srOnly">סגור חלונית תפריט</Typography>
                </CloseButton>
              </StyledCloseContainer>
            </StyledSocialCloseWrapper>
          </Desktop>
        </StyledWrapper>
      </StyledContainer>
    </StyledDrawer>
  )
}

export default HeaderMenu
