import { Tab, Tabs } from "@material-ui/core"
import { StyledFlexBoxRow } from "components/searchAmot/SearchAmotPage"
import { useLayoutContext } from "context/LayoutContext"
import { useTranslations } from "next-intl"
import Link from "next/link"
import { useRouter } from "next/router"
import React from "react"
import styled from "styled-components"
import colors from "theme/colors"
import { MainRoutes } from "~/routes/mainRoutes"
import SubLinksList from "./SubLinksList"
import TabPanel, { a11yProps } from "./TabPanel"

const headerOptionsList = [
  "assetsList",
  "investments",
  "relations",
  "mallsAndShopping",
  "amot360",
  "construction",
] as const

const StyledTabs = styled(Tabs)<{ $hebrew: boolean }>`
  width: 200px;
  position: relative;
  //padding-left: 2px;
  z-index: 2;

  span {
    z-index: 2;
    //left: -1px;
    //overflow: visible;
    margin-left: ${(p) => p.$hebrew && "-1px"};
    margin-right: ${(p) => p.$hebrew && "-1px"};
    right: ${(p) => !p.$hebrew && "-1px"};
    width: 3px;
  }

  &:after {
    content: "";
    position: absolute;
    left: ${(p) => p.$hebrew && 0};
    right: ${(p) => !p.$hebrew && 0};
    top: 50%;
    transform: translate(${(p) => (p.$hebrew ? "-50%" : "50%")}, -50%);
    width: 3px;
    height: 100%;
    background: grey;
  }

  ${(p) => (p.$hebrew ? ` margin-left: 45px;` : ` margin-right: 45px;`)}
`
const StyledTab = styled(Tab)<{ $selected: boolean }>`
  align-items: start;
  opacity: 1;
  background-color: transparent !important;
  text-align: start;
  .MuiTab-wrapper {
    border: none;
    background-color: transparent;
    width: 100% !important;
    padding: 0;
    justify-content: start;
    align-items: start;
    color: ${(p) => p.theme.colors.black};
    font-size: 16px;
    font-weight: normal;
    ${(p) =>
      p.$selected
        ? `color : ${p.theme.colors.red} !important; font-weight: 500;`
        : ``}

    white-space:pre-line;
  }
`

type HeaderDesktopTabsProps = {
  selectedValue: number
  handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void
  onClose: () => void
}

const HeaderDesktopTabs = ({
  selectedValue,
  handleChange,
  onClose,
}: HeaderDesktopTabsProps) => {
  const { mallsItems } = useLayoutContext()

  const t = useTranslations("headerMenu")
  const { locale } = useRouter()

  return (
    <StyledFlexBoxRow>
      <StyledTabs
        orientation="vertical"
        value={selectedValue}
        onChange={handleChange}
        aria-label="tabs Menu"
        TabIndicatorProps={{ style: { background: colors.red } }}
        $hebrew={locale === "he"}
      >
        <StyledTab
          label={t("assets")}
          {...a11yProps(0)}
          $selected={selectedValue === 0}
        />
        <StyledTab
          label={t("investments")}
          {...a11yProps(1)}
          $selected={selectedValue === 1}
        />
        <StyledTab
          label={t("relations")}
          {...a11yProps(2)}
          $selected={selectedValue === 2}
        />

        <StyledTab
          label={t("mallsAndShopping")}
          {...a11yProps(3)}
          $selected={selectedValue === 3}
        />

        <Link href="/amot360">
          <StyledTab
            label={t("amot360")}
            {...a11yProps(4)}
            $selected={selectedValue === 4}
          />
        </Link>

        <Link href="/construction">
          <StyledTab
            label={t("construction")}
            {...a11yProps(5)}
            $selected={selectedValue === 5}
          />
        </Link>
      </StyledTabs>
      {headerOptionsList.map((item, index) => {
        if (item === "amot360") {
          return
        }
        if (item === "construction") {
          return
        }
        return (
          <TabPanel value={selectedValue} index={index} key={index}>
            <SubLinksList
              onClose={onClose}
              title={t(item)}
              linksObject={MainRoutes[item]}
              layoutItems={selectedValue === 3 ? mallsItems : null}
            />
          </TabPanel>
        )
      })}
    </StyledFlexBoxRow>
  )
}

export default HeaderDesktopTabs
