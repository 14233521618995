import {
  Button,
  ClickAwayListener,
  Container,
  Dialog,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core"
import { SupermarketPage } from "api"
import { AssetListWithoutSupermarketType } from "common/AssetType"
import { Mobile } from "common/MediaQueries"
import NoResultsWrap from "components/searchAmot/NoResultsWrap"
import SearchResultBox from "components/searchAmot/SearchResultBox"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useTranslations } from "next-intl"
import Image from "next/image"
import styled from "styled-components"
import colors from "theme/colors"
import CategoriesDisplay from "./CategoriesDisplay"
import CategoryAndListOrMap from "./CategoryAndListOrMap"
import { FormAssetsSearch } from "./FormAssetsSearch"

const StyledDialog = styled(Dialog).attrs({ fullScreen: true })`
  top: 60px !important;
  /* z-index: 1009 !important; */

  .MuiBackdrop-root {
    top: inherit;
  }
  .MuiDialog-container {
    &:focus-visible {
      border: 1px dashed white;
      outline-offset: 2px;
      outline: 2px solid black;
    }
    ${(p) => p.theme.breakpoints.down("sm")} {
      align-items: flex-start;
    }
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    margin: 10px;
    margin-top: 0;
  }
  .MuiDialog-paper.MuiPaper-root {
    top: inherit;
    box-shadow: none;
    background-color: transparent;
    ${(p) => p.theme.breakpoints.down("sm")} {
      max-height: calc(100% - 80px);
    }
  }

  /* .MuiAutocomplete-root {
    display: flex;
    align-items: center;
    z-index: 3000;
    transform: translateX(-50%);
    left: 50%;
    position: absolute;
  }
  .MuiInputBase-root {
    padding-bottom: 7px;
  } */
  /* ${(p) => p.theme.breakpoints.down("sm")} {
    right: 0;

    .MuiAutocomplete-popper {
      width: calc(100% - 32px) !important;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
    }
    .MuiAutocomplete-root {
      position: absolute;
      top: 5px;
      width: calc(100% - (16px * 2));
      background-color: ${colors.white};
      height: 57px;
      padding: 0 16px;
      border-radius: 7px;
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
    }
  } */

  /* ${(p) => p.theme.breakpoints.up("md")} {
    top: 60px;
    .MuiAutocomplete-popper {
      width: 100% !important;
      top: 80px;
    }
    .MuiAutocomplete-root {
      top: 10px;
      background-color: ${colors.white};
      height: 65px;
      padding: 0 10px 0 24px;
      border-radius: 7px;
      border-radius: 10px;
      max-width: inherit;
      width: 100%;
      box-shadow: 0 2px 7px 0 rgb(0 0 0 / 16%);

      .MuiInputBase-root {
        padding-bottom: 20px;
      }
    }
  } */
`

const StyledContainer = styled(Container)`
  background-color: white;
  position: absolute;
  ${(p) => p.theme.breakpoints.down("sm")} {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-right: 6px;
    padding-left: 6px;
    overflow: auto;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    border-radius: 20px;
    top: 10px;
    background-color: transparent;
  }
`
const StyledInputWrapper = styled.div<{ $openCategoriesDrawer?: boolean }>`
  border-radius: 7px;
  padding-right: 22px;
  top: 5px;
  position: absolute;
  background: white;
  z-index: 1203;
  width: 100%;
  right: 0;
  left: 0;
  ${(p) => p.theme.breakpoints.up("md")} {
    position: relative;
    margin: 0;
    padding: 0;
    top: 0;
    border-radius: 20px;

    .MuiBox-root {
      padding: 20px;
      border-bottom: solid 1px ${(p) => p.theme.colors.activitiesBorder};
      border-radius: 20px;
    }
    .MuiButtonBase-root {
      ${(p) =>
        !p.$openCategoriesDrawer &&
        `margin-inline-end:0;margin-inline-start:auto;`}

      ${(p) =>
        p.$openCategoriesDrawer &&
        `margin-inline-end:0;
          margin-inline-start:auto;
          &:first-child{
          margin-inline-start:0;

          }
          `}
    }
  }

  ${(p) => p.theme.breakpoints.down("sm")} {
    height: 57px;
    padding: 0 10px;
    .MuiBox-root {
      padding: 0 10px;
      border-bottom: solid 1px ${(p) => p.theme.colors.activitiesBorder};
    }
    .MuiButton-text {
      margin: 0;
      margin-right: auto;
    }
  }

  .MuiTextField-root {
    height: auto;
    min-width: 0;
    width: 100%;

    input {
      width: 100%;
    }
  }
`

const StyledResultsBoxContainer = styled.div`
  /* position: absolute; */
  margin-top: 106px;
  width: 100%;
  /* left: 50%; */
  /* transform: translateX(-50%); */
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-right: 0;
    margin-left: 0;
    margin-top: 0;
    overflow-y: auto;
    padding-inline-end: 16px;
    padding-inline-start: 20px;
    border-radius: 20px;

    div {
      > a {
        max-width: 375px;
      }
    }
  }
  ${(p) => p.theme.breakpoints.down("sm")} {
    padding-top: 55px;
  }
`

export const StyledDrawer = styled(Drawer)`
  width: 100%;
  height: 100%;
  inset: 123px 0 0 0;
  display: block;
  position: fixed;
  z-index: 1202;
  overflow: hidden;
  ${(props) => (!props.open ? `pointer-events: none;` : ``)};

  .MuiDrawer-paper {
    top: 0;
    border-radius: 0;
    z-index: 4500;
    border: 0;
    position: absolute;
    box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
    ${(p) => p.theme.breakpoints.up("md")} {
      box-shadow: none;
      padding-top: 50px;
      padding-right: 130px;
      padding-left: 130px;
      > div {
        justify-content: flex-start;
      }
    }
  }
`

const StyledContentWrapper = styled.div`
  background-color: white;
  ${(p) => p.theme.breakpoints.up("md")} {
    padding-inline-end: 0;
    border-radius: 20px;
    max-height: 605px;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px ${(p) => p.theme.colors.activitiesBorder};
  }
`
const StyledDrawerContainer = styled(Container)`
  position: relative;

  ${(p) => p.theme.breakpoints.down("sm")} {
    .MuiDrawer-root {
      width: calc(100% - 20px);
      margin: 0 10px;
      .MuiPaper-root {
        border-radius: 20px;
      }
    }
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    .MuiPaper-root {
      background-color: transparent;
      padding: 5px 100px;
    }
  }

  .MuiDrawer-paper {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`

export const StyledArrow = styled(IconButton)`
  right: 0;
  top: 10px;
  position: absolute;
  border-radius: 50%;
  width: 50px;
  height: 50px;
`

const StyledCloseContainer = styled.div`
  border-radius: 25px;
  width: 50px;
  height: 50px;
  position: absolute;
  inset: 0 42px 0 42px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .MuiButton-startIcon {
    margin: 0;
  }
`
const CloseButton = styled(Button)`
  font-family: FbJabutinskiEng, FbJabutinski;
  font-size: 16px;
  line-height: 1.38;
  color: #000615;
  display: flex;
  font-weight: 500;
  p {
    display: flex;
    align-items: center;
    margin: 0;
    height: 100%;
  }
`

const AssetsResultsList = () => {
  const {
    selectedAssetsResults,
    setSelectedAssetsResult,
    activeCategoryType,
    setOpenCategoriesDrawer,
    openCategoriesDrawer,
    openMapOrList,
    setOpenMapOrList,
    isChanging,
  } = useAssetSearchContext()
  const t = useTranslations("searchPopup")

  const handleClose = () => {
    setOpenMapOrList("")
  }
  return (
    <StyledDialog
      open={openMapOrList === "list"}
      onClose={handleClose}
      PaperProps={{
        "aria-modal": true,
        tabIndex: -1,
      }}
      aria-labelledby="dialogTitle"
    >
      <ClickAwayListener onClickAway={handleClose}>
        <StyledContainer style={{ position: "relative" }}>
          <Typography id="dialogTitle" component="h2" variant="srOnly">
            {t("assetSearchListTitle")}
          </Typography>

          <StyledCloseContainer>
            <CloseButton
              onClick={handleClose}
              startIcon={
                <Image
                  alt=""
                  src="/menu-icon-2.svg"
                  width="12.7px"
                  height="12.7px"
                />
              }
            ></CloseButton>
          </StyledCloseContainer>
          <StyledContentWrapper>
            <StyledInputWrapper $openCategoriesDrawer={openCategoriesDrawer}>
              <FormAssetsSearch
                autoComplete="search-assets"
                onSearch={setSelectedAssetsResult}
                name="search-assets"
                label={`${t("lookingFor")}${t(activeCategoryType)}`}
                extraLabel={t("assetSearchListTitle")}
              />
            </StyledInputWrapper>
            <StyledDrawerContainer>
              <StyledDrawer
                variant="persistent"
                open={openCategoriesDrawer}
                anchor="top"
                PaperProps={{ component: Container }}
              >
                <Mobile>
                  <StyledArrow onClick={() => setOpenCategoriesDrawer(false)}>
                    <Image width={14} height={7} src="/arrow-simple-down.svg" />
                  </StyledArrow>
                </Mobile>
                <CategoriesDisplay />
              </StyledDrawer>
            </StyledDrawerContainer>

            <Mobile>
              <CategoryAndListOrMap />
            </Mobile>

            <StyledResultsBoxContainer>
              {isChanging ? (
                <NoResultsWrap isChanging={true} />
              ) : (
                !openCategoriesDrawer &&
                selectedAssetsResults &&
                selectedAssetsResults?.map((result, i) => (
                  <SearchResultBox
                    key={i}
                    data={{
                      title: result?.title,
                      slug: result?.slug || "",
                      // activeCategoryType
                      image:
                        activeCategoryType === "supermarket"
                          ? (result as SupermarketPage)?.logo?.media?.publicUrl
                          : (result as AssetListWithoutSupermarketType)
                              ?.mainExtended?.logoMobile?.media?.publicUrl,
                      imageMobile:
                        activeCategoryType === "supermarket"
                          ? (result as SupermarketPage)?.logo?.media?.publicUrl
                          : (result as AssetListWithoutSupermarketType)
                              ?.mainExtended?.logoMobile?.media?.publicUrl,
                      type: "not file",
                      description: (result as AssetListWithoutSupermarketType)
                        ?.mainExtended?.description?.value,
                      link: "",
                      reportFileLink: "",
                    }}
                  />
                ))
              )}

              {!openCategoriesDrawer &&
                selectedAssetsResults?.length === 0 &&
                !isChanging && <NoResultsWrap />}
            </StyledResultsBoxContainer>
          </StyledContentWrapper>
        </StyledContainer>
      </ClickAwayListener>
    </StyledDialog>
  )
}

export default AssetsResultsList
