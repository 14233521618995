import { Button, IconButton } from "@material-ui/core"
import { useAssetSearchContext } from "context/AssetSearchContext"
import { useTranslations } from "next-intl"
import Image from "next/image"
import styled from "styled-components"
import colors from "theme/colors"

const StyledCategoyAndList = styled.div<{ $isMap?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1200;
  top: ${(p) => (p.$isMap ? `80px` : `70px`)};
  left: 50%;
  padding-right: ${(p) => (p.$isMap ? `26px` : `6px`)};
  padding-left: ${(p) => (p.$isMap ? `26px` : `6px`)};
  transform: translateX(-50%);
  ${(p) => p.theme.breakpoints.down("sm")} {
    border-bottom: ${(p) =>
      p.$isMap ? `none` : `solid 1px ${p.theme.colors.activitiesBorder}`};
    padding-top: ${(p) => (p.$isMap ? `0` : `20px`)};
    padding-bottom: ${(p) => (p.$isMap ? `0` : `20px`)};
  }
`

const StyledCategoryWrapper = styled(IconButton)`
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
  height: 45px;
  width: 45px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${colors.white};
`

const StyledListButton = styled(Button)`
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.alabaster};
  border-radius: 6px;
  height: 45px;
  padding: 10px;
  width: auto;
  .Mui-Button-label {
    display: flex;
    align-items: center;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
    height: 33px;
    background-color: ${colors.alabaster};
  }
`
const StyledIconList = styled.span`` //modify to next
const StyledList = styled.span`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.gray};
  margin-left: 5px;
  margin-top: 2px;
`

const CategoryAndListOrMap = () => {
  const {
    activeCategoryType,
    openMapOrList,
    setOpenCategoriesDrawer,
    setOpenMapOrList,
  } = useAssetSearchContext()

  const t = useTranslations("searchPopup")

  return (
    <StyledCategoyAndList $isMap={openMapOrList === "map"}>
      <StyledCategoryWrapper
        onClick={() => {
          setOpenCategoriesDrawer(true)
        }}
      >
        <Image
          width={45}
          height={45}
          src={`/${activeCategoryType}-not-selected.png`}
        />
      </StyledCategoryWrapper>
      {!!openMapOrList?.length && (
        <>
          {openMapOrList === "list" && (
            <StyledListButton onClick={() => setOpenMapOrList("map")}>
              <StyledList>{t("map")}</StyledList>
              <StyledIconList>
                <Image width={18} height={18} src="/location-icon.svg" />
              </StyledIconList>
            </StyledListButton>
          )}
          {openMapOrList === "map" && (
            <StyledListButton onClick={() => setOpenMapOrList("list")}>
              <StyledList>{t("list")}</StyledList>
              <Image width={18} height={18} src="/metro-list.svg" />
            </StyledListButton>
          )}
        </>
      )}
    </StyledCategoyAndList>
  )
}

export default CategoryAndListOrMap
